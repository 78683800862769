<template>
  <div class="inte-exch-record">
    <!-- start 条件过滤区 -->
    <div class="df-row">
      <!-- 日期时间选择器 -->
      <div class="df-col">
        <el-date-picker
          v-model="multipleDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="mini"
          value-format="yyyy-MM-dd"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>

      <!-- 门店选择器 -->
      <div class="df-col">
        <el-select
          v-model="submitData.stores_guid"
          placeholder="请选择员工级别"
          size="mini"
        >
          <el-option
            v-for="item in storesList"
            :key="item.StoresGuid"
            :label="item.StoresName"
            :value="item.StoresGuid"
          >
          </el-option>
        </el-select>
      </div>

      <!-- 查询文本框 -->
      <div class="df-col">
        <el-input v-model="submitData.word" size="mini" />
      </div>

      <!-- 查询按钮 -->
      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearchData">
          查询
        </el-button>
      </div>

      <!--新增按钮 -->
      <div class="df-col">
        <el-button type="primary" size="mini" @click="onShowAdd">
          新增兑换
        </el-button>
      </div>
    </div>
    <!-- end 条件过滤区 -->

    <!-- start 积分兑换记录 -->
    <el-table
      :data="tableData"
      :max-height="tableMaxHeight"
      border
      stripe
      v-loading="loading"
    >
      <el-table-column prop="BillNo" label="单号"> </el-table-column>
      <el-table-column prop="MemberName" label="会员名称"> </el-table-column>
      <el-table-column prop="MemberPhone" label="会员电话"> </el-table-column>
      <!-- <el-table-column prop="VipNo" label="会员卡号"> </el-table-column> -->
      <!-- <el-table-column prop="CardName" label="卡类型"> </el-table-column> -->
      <!-- <el-table-column prop="NatureName" label="卡属性"> </el-table-column> -->
      <!-- <el-table-column prop="GoodsNo" label="物品编号"> </el-table-column> -->
      <el-table-column prop="GoodsName" label="物品名称"> </el-table-column>
      <!-- <el-table-column prop="UnitsName" label="物品单位"> </el-table-column> -->
      <!-- <el-table-column prop="ExchCount" label="兑换数量"> </el-table-column> -->
      <!-- <el-table-column prop="AmountMoney" label="兑换总金额"> </el-table-column> -->
      <el-table-column prop="AmountInte" label="消耗总积分"> </el-table-column>
      <el-table-column label="记账日期">
        <template slot-scope="scope">
          {{ dateFormat("YYYY-mm-dd", scope.row.SoftwareDate) }}
        </template>
      </el-table-column>
      <el-table-column prop="AddTime" label="兑换时间">
        <template slot-scope="scope">
          {{ dateFormat("YYYY-mm-dd HH:MM:SS", scope.row.AddTime) }}
        </template></el-table-column
      >
      <el-table-column label="操作" :width="columnMaxWidth">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="onSelectExchRecord(scope.row)"
            >详情</el-button
          >

          <el-button
            :type="scope.row.VerifyMark ? 'info' : 'success'"
            size="mini"
            :disabled="verifyDisabled"
            @click="onVerifyExchRecord(scope.row)"
            >{{ scope.row.VerifyMark ? "取消核销" : "核销" }}</el-button
          >

          <el-button
            v-show="scope.row.VerifyMark == 0"
            :type="'danger'"
            size="mini"
            :disabled="reverseDisabled"
            @click="onReverseExchRecord(scope.row)"
            >消单</el-button
          >

          <!-- ExportStatus 同步状态 0无需同步 1需要同步 2同步完成 3消单失败，撤销同步 -->
          <el-button
            v-show="scope.row.ExportStatus == 1 || scope.row.ExportStatus == 3"
            :type="scope.row.ExportStatus == 1 ? '' : 'info'"
            size="mini"
            :disabled="exportDisabled"
            @click="onExportExchRecord(scope.row)"
            >{{
              scope.row.ExportStatus == 1 ? "同步积分" : "撤销同步"
            }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- end 积分兑换记录 -->

    <!-- start 分页器 -->
    <el-pagination
      background
      :page-sizes="[5, 10, 15, 20]"
      :page-size="submitData.page_size"
      :current-page="submitData.page_index"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <!--end 分页器-->

    <!-- start 积分兑换记录详情对话框 -->
    <InteExchRecordDetailDialog
      ref="inteExchRecordDetailDialog"
    ></InteExchRecordDetailDialog>
    <!-- end 积分兑换记录详情对话框 -->

    <!-- start 新增积分兑换记录对话框 -->
    <InteExchRecordAddDialog
      ref="inteExchRecordAddDialog"
      @success="onSearchData"
    ></InteExchRecordAddDialog>
    <!-- start 新增积分兑换记录对话框 -->
  </div>
</template>

<script>
import Integral from "@/api/integral.js";
import InteExchRecordDetailDialog from "./components/inte-exch-record-detail-dialog.vue";
import InteExchRecordAddDialog from "./components/inte-exch-record-add.vue";

export default {
  components: { InteExchRecordDetailDialog, InteExchRecordAddDialog },

  data() {
    return {
      submitData: {
        // 提交数据
        begin_date: "", // 开始日期
        end_date: "", // 结束日期
        word: "", // 关键词
        stores_guid: "", // 门店id
        page_index: 1, // 页码
        page_size: 10, // 页数
      },
      storesList: [], // 门店列表
      tableData: [], // 积分兑换数据列表
      multipleDate: [], // 日期时间选择器数据
      totalRecord: 0, // 分页器总页数
      tableMaxHeight: "", // 表格最大高度
      columnMaxWidth: "", // 列单元格最大宽度
      loading: false, // 表格加载
      verifyDisabled: false, // 核销禁用
      reverseDisabled: false, // 消单禁用
      exportDisabled: false, // 同步禁用
    };
  },

  computed: {
    // 时间格式化函数
    dateFormat() {
      return function(fmt, timestamp) {
        return this.util.dateFormat(fmt, new Date(timestamp * 1000));
      };
    },
  },

  created() {
    this.$store.dispatch("initRxcode");
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);

    this.initExchangeRecordList();
    this.multipleDate = [
      this.util.getNowMonthToday(),
      this.util.getNowMonthToday(),
    ];
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 68 + 32);
    },

    // 初始化门店列表
    async initExchangeRecordList() {
      try {
        let { data, errcode } = await Integral.initExchangeRecordList();
        let storesList = [
          { StoresGuid: "", StoresName: "全部门店" },
          ...data.storeslist,
        ];
        this.storesList = storesList;
      } catch (error) {}
    },

    // 初始化表格数据
    async initPageData() {
      this.loading = true;
      try {
        let { data, errcode } = await Integral.getExchangeRecordList(
          this.submitData
        );
        let columnMaxWidth = 260;

        data.list.find((item) => {
          return item.ExportStatus > 0 && item.ExportStatus != 2
            ? (columnMaxWidth += 60)
            : "";
        });

        this.tableData = data.list;
        this.totalRecord = data.filter.TotalRecord;
        this.columnMaxWidth = columnMaxWidth;
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },

    // 查询按钮点击事件
    onSearchData() {
      this.submitData.page_index = 1;
      this.initPageData();
    },

    // 日期时间改变事件
    onChangeDate() {
      this.submitData.begin_date =
        this.multipleDate.length > 0
          ? this.multipleDate[0]
          : this.util.getNowMonthToday();
      this.submitData.end_date =
        this.multipleDate.length > 0
          ? this.multipleDate[1]
          : this.util.getNowMonthToday();
      this.submitData.page_index = 1;
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      this.initPageData();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.initPageData();
    },

    // 打开新增积分兑换记录对话框
    onShowAdd() {
      this.$refs.inteExchRecordAddDialog.onShowDialog()
    },

    // 详情按钮点击事件
    onSelectExchRecord(event) {
      this.$refs.inteExchRecordDetailDialog.onShowDialog("detail", event);
    },

    // 核销、取消核销 按钮点击事件
    onVerifyExchRecord(event) {
      this.verifyDisabled = true;

      try {
        let text = event.VerifyMark ? "取消核销" : "核销";
        this.$confirm(`您是否确定要 ${text}?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let submitData = {
              exch_guid: event.ExchGuid,
              mark: event.VerifyMark ? 0 : 1,
            };
            let { errcode } = await Integral.verifyExchangeRecord(submitData);
            if (errcode == 0) {
              this.$message.success(`${text}成功`);
              this.initPageData();
            }
          })
          .finally(() => {
            this.verifyDisabled = false;
          });
      } catch (error) {}
    },

    // 消单按钮点击事件
    onReverseExchRecord(event) {
      this.reverseDisabled = true;

      try {
        this.$confirm(`您是否确定要消单?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let submitData = {
              exch_guid: event.ExchGuid,
            };
            let { errcode } = await Integral.reverseExchangeRecord(submitData);
            if (errcode == 0) {
              this.$message.success("消单成功");
              this.initPageData();
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.reverseDisabled = false;
          });
      } catch (error) {}
    },

    // 同步、撤销同步按钮点击时间
    onExportExchRecord(event) {
      this.exportDisabled = true;

      try {
        let text = event.ExportStatus == 1 ? "同步积分" : "撤销同步积分";
        this.$confirm(`您是否确定要 ${text}?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let submitData = {
              exch_guid: event.ExchGuid,
            };
            let { errcode } =
              event.ExportStatus == 1
                ? await Integral.manualApplyExportTarget(submitData)
                : await Integral.manualReverseExportTarget(submitData);
            if (errcode == 0) {
              this.$message.success(`${text}成功`);
              this.initPageData();
            }
          })
          .finally(() => {
            this.exportDisabled = false;
          });
      } catch (error) {}
    },
  },
};
</script>

<style lang="less" scoped>
.inte-exch-record {
  .df-row {
    display: flex;
    margin: 20px 0;
    .df-col {
      margin-right: 10px;
    }
  }
}

.el-pagination {
  margin-top: 20px;
  text-align: right;
}
</style>
